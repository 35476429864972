<template>
    <div class="card block">
        <div class="card-content pb-0">
            <h1 class="title is-5">Value</h1>

            <b-field grouped
                     label="Discount amount">
                <b-input placeholder="HK$ 0.00"
                         v-cleave="mask"
                         v-bind:value="discountAmountFormatted"
                         v-on:input.native="onInput">
                </b-input>
            </b-field>
        </div>
        <hr>
        <div class="card-content pt-0">
            <h1 class="title is-6">Applies to</h1>

            <b-field>
                <b-radio native-value="all"
                         v-model="applicableTo"
                         v-on:input="resetCustomerBuys">
                    All products
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="specific"
                         v-model="applicableTo">
                    Specific products
                </b-radio>
            </b-field>

            <product-selector label="Targets"
                              v-if="applicableTo === 'specific'"
                              v-model="customerBuys">
            </product-selector>
        </div>
    </div>
</template>

<script>
import cleave, { monetary } from "../../../../directives/VCleave";
import ProductSelector from "../ProductSelector";

export default {
    name: "CouponFixedAmount",
    components: {
        ProductSelector
    },
    directives: {
        cleave,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {
            "mask": monetary,
            "discountAmountFormattedInStore": null,
            "applicableToInStore": "",
        };
    },
    computed: {
        discountAmount: {
            get () {
                return this.value?.discountAmount ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "discountAmount": val }
                );
            }
        },
        discountAmountFormatted: {
            get () {
                return this.discountAmountFormattedInStore || this.discountAmount;
            },
            set (val) {
                this.discountAmountFormattedInStore = val;
            }
        },

        customerBuys: {
            get () {
                return this.value?.customerBuys ?? [];
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "customerBuys": val }
                );
            },
        },

        applicableTo: {
            get () {
                if (this.applicableToInStore) {
                    return this.applicableToInStore;
                }
                return this.customerBuys?.length > 0 ? "specific" : "all";
            },
            set (val) {
                this.applicableToInStore = val;
            }
        },
    },
    methods: {
        onInput (evt) {
            this.discountAmountFormatted = evt.target._vCleave.getFormattedValue();
            this.discountAmount = evt.target._vCleave.getRawValue().replace(/\D/g, "").trim();
        },
        resetCustomerBuys () {
            if (this.applicableTo === "all") {
                this.customerBuys = [];
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
