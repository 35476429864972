<template>
    <div class="card mb-4">
        <div class="card-content">
            <h1 class="title is-5">Basic info</h1>

            <b-field label="Code"
                     grouped
                     message="Customers will enter this code at checkout">
                <b-input type="text"
                         required
                         placeholder="e.g. COUPONCODE"
                         v-model="code">
                </b-input>
                <button class="button"
                        type="button"
                        v-on:click.prevent.stop="generate">
                    Generate
                </button>
            </b-field>

            <b-field label="Description"
                     message="Some optional text to explain the coupon">
                <b-input type="text"
                         placeholder="Buy one get one free"
                         v-model="descriptionInComponent">
                </b-input>
            </b-field>
        </div>
    </div>
</template>

<script>
export default {
    name: "CouponCode",
    props: {
        value: {
            type: String,
        },

        description: {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        code: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit("input", val);
            },
        },

        descriptionInComponent: {
            get () {
                return this.description
            },
            set (val) {
                this.$emit("update:description", val);
            },
        }
    },
    methods: {
        generate () {
            const code = Math
                .random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .toUpperCase()
                .substr(0, 10);
            this.$emit("input", code);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
