<template>
    <div class="card block">
        <div class="card-content">
            TBA
        </div>
    </div>
</template>

<script>
export default {
    name: "CouponFreeShipping",
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
