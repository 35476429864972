<template>
    <div class="card block">
        <div class="card-content">
            <h1 class="title has-text-weight-bold is-5">
                Summary
            </h1>
            <div class="mb-4">
                <h1 class="title has-text-weight-bold">
                    {{ coupon.code }}
                </h1>
                <h2 class="subtitle">
                    {{ coupon.description }}
                </h2>
            </div>
            <div class="">
                <div class="has-text-weight-bold is-size-4">
                    {{ typeMap.get(coupon.type) }}
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'percentage'">
                    {{ coupon.discountRate }}% OFF
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'fixed-amount'">
                    HK${{ coupon.discountAmount }} OFF
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'buy-x-get-y'">
                    BUY {{ coupon.getQuantity }} GET {{ coupon.getDiscount }}% OFF for {{ coupon.buyQuantity }}
                </div>
            </div>
            <div>
                <ul>
                    <li>
                        - {{ minRequirement }}
                    </li>
                    <li v-if="maxUsePerOrder">
                        - {{ maxUsePerOrder }}
                    </li>
                    <li v-if="maxTotalTimes">
                        - {{ maxTotalTimes }}
                    </li>
                    <li v-if="isLimitedToOnePerUser">
                        - {{ isLimitedToOnePerUser }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
const typeMap = new Map(
    [
        ["percentage", "Percentage"],
        ["fixed-amount", "Fixed Amount"],
        ["buy-x-get-y", "Buy X Get Y"],
    ]
);

export default {
    name: "CouponSummary",
    props: {
       coupon: {
           type: Object,
       },
    },
    data () {
        return {
            typeMap,
        };
    },
    computed: {
        minRequirement () {
            return this.coupon.hasMinAmount === true ?
                `Minimum purchase of HK$${ this.coupon.minAmount } worth of items` :
                this.coupon.hasMinQuantity === true ?
                    `Minimum purchase of ${ this.coupon.minQuantity } items` :
                    `No minimum requirement`;
        },
        maxUsePerOrder () {
            return this.coupon.maxUsePerOrder ?
                `Apply ${ this.coupon.maxUsePerOrder } time${ this.coupon.maxUsePerOrder > 1 ? "(s)" : "" } to an order` :
                "";
        },
        maxTotalTimes () {
            return this.coupon.maxTotalTimes ?
                `Coupon can be applied to ${ maxTotalTimes } orders` :
                "";
        },
        isLimitedToOnePerUser () {
            return this.coupon.isLimitedToOnePerUser === true ?
                "Each user can apply once only" :
                "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
