<template>
    <div class="card block">
        <div class="card-content">
            <h1 class="title is-5">Usage limits</h1>

            <b-field>
                <b-checkbox v-model="isLimitedMaxTotalTimes"
                            v-on:input="resetMaxTotalTimes">
                    Limit number of times this coupon can be used in total
                </b-checkbox>
            </b-field>
            <b-field grouped
                     message="Applies to all products."
                     v-show="isLimitedMaxTotalTimes">
                <b-input min="1"
                         v-cleave="mask"
                         v-bind:required="isLimitedMaxTotalTimes"
                         v-bind:value="maxTotalTimesFormatted"
                         v-on:input.native="onInput">
                </b-input>
            </b-field>

            <b-field>
                <b-checkbox v-model="isLimitedToOnePerUser">
                    Limit to one use per customer
                </b-checkbox>
            </b-field>
        </div>
    </div>
</template>

<script>
import cleave, { positiveInteger, } from "../../../directives/VCleave";

export default {
    name: "CouponUsage",
    directives: {
        cleave,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {
            "mask": positiveInteger,
            maxTotalTimesFormatted: "",
            isLimitedMaxTotalTimes: false,
        };
    },
    computed: {
        maxTotalTimes: {
            get () {
                return this.value?.maxTotalTimes ?? "";
            },
            set (val) {
                const temp = Object.assign({}, this.value);
                if (val) {
                    temp.maxTotalTimes = val;
                } else {
                    delete temp.maxTotalTimes;
                }
                this.$emit(
                    "input",
                    temp
                );
            }
        },
        isLimitedToOnePerUser: {
            get () {
                return this.value?.isLimitedToOnePerUser ?? true;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "isLimitedToOnePerUser": val, }
                );
            }
        },
    },
    methods: {
        onInput (evt) {
            this.maxTotalTimesFormatted = evt.target._vCleave.getFormattedValue();
            this.maxTotalTimes = evt.target._vCleave.getRawValue().replace(/\D/g, "").trim();
        },

        resetMaxTotalTimes () {
            if (this.isLimitedMaxTotalTimes === false) {
                this.maxTotalTimesFormatted = "";
                this.maxTotalTimes = "";
            }
        },
    },
    mounted () {
        this.$emit(
            "input",
            { ...this.value, "isLimitedToOnePerUser": true }
        );
    },
}
</script>

<style lang="scss" scoped>

</style>
