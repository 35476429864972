<template>
    <section class="coupon-details">
        <form v-on:submit.prevent.stop="save">
            <tool-bar>
                {{ mode }} coupon <strong v-if="mode === 'Update'">{{ code }}</strong>
                <template slot="buttons">
                    <div class="level-item">
                        <button class="button"
                                type="submit"
                                v-if="mode === 'Create'"
                                v-bind:class="{ 'is-loading': isLoading }"
                                v-bind:disabled="!allowSave">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                            <span>Save</span>
                        </button>
                        <button class="button"
                                type="submit"
                                v-if="mode === 'Update'"
                                v-bind:class="{ 'is-loading': isLoading }"
                                v-bind:disabled="!allowSave">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                            <span>Update</span>
                        </button>
                    </div>
                </template>
            </tool-bar>

            <div class="container mt-4 mb-6">
                <coupon-summary v-bind:coupon="{ type, code, description, ...typeParams, ...couponParams }"></coupon-summary>
                <coupon-code v-bind:description.sync="description"
                             v-model="code">
                </coupon-code>
                <coupon-type v-model="type"></coupon-type>
                <component v-bind:is="activeTypeComponent"
                           v-model="typeParams">
                </component>
                <coupon-minimum-requirements v-model="couponParams"></coupon-minimum-requirements>
                <coupon-usage v-model="couponParams"></coupon-usage>
                <coupon-active-dates v-model="couponParams"></coupon-active-dates>
            </div>
        </form>
    </section>
</template>

<script>
import ToolBar from "../layouts/ToolBar";
import CouponType from "./CouponDetails/CouponType";
import CouponCode from "./CouponDetails/CouponCode";

import Percentage from "./CouponDetails/type/Percentage";
import FixedAmount from "./CouponDetails/type/FixedAmount";
import FreeShipping from "./CouponDetails/type/FreeShipping";
import BuyXGetY from "./CouponDetails/type/BuyXGetY";
import CouponMinimumRequirements from "./CouponDetails/CouponMinimumRequirements";
import CouponUsage from "./CouponDetails/CouponUsage";
import CouponActiveDates from "./CouponDetails/CouponActiveDates";
import CouponSummary from "./CouponDetails/CouponSummary";

const couponTypeComponentMap = new Map(
    [
        ["percentage", "Percentage"],
        ["fixed-amount", "FixedAmount"],
        ["free-shipping", "FreeShipping"],
        ["buy-x-get-y", "BuyXGetY"],
    ]
);

const couponTypeRequiredFieldMap = new Map(
    [
        [
            "percentage",
            ["customerBuys", "discountRate",]
        ],
        [
            "fixed-amount",
            ["customerBuys", "discountAmount",]
        ],
        ["free-shipping", []],
        [
            "buy-x-get-y",
            ["customerBuys", "buyQuantity", "buyAmount", "customerGets", "getQuantity", "getDiscount", "maxUsePerOrder",]
        ],
    ]
);

export default {
    name: "Coupon",
    components: {
        CouponSummary,
        CouponActiveDates,
        CouponUsage,
        CouponMinimumRequirements,
        ToolBar,
        CouponCode,
        CouponType,
        Percentage, FixedAmount, FreeShipping, BuyXGetY,
    },
    props: {
        "couponId": {
            type: String,
        },
    },
    data () {
        return {
            mode: this.couponId ? "Update" : "Create",
            isLoading: false,

            lang: "en",
            enableTcToSc: true,

            code: "",
            description: "",
            type: "percentage",
            typeParams: null,
            couponParams: null,
        };
    },
    computed: {
        allowSave () {
            return true;
        },
        activeTypeComponent () {
            return couponTypeComponentMap.get(this.type);
        },
        coupons () {
            return this.$store.getters["coupon/coupons"];
        },
        coupon () {
            return this.coupons.find(
                ({ _id }) => _id === this.couponId
            );
        },
    },
    methods: {
        save () {
            if (this.mode === "Create") {
                return this.addCoupon();
            }
            return this.editCoupon();
        },
        async addCoupon () {
            this.isLoading = true;
            const { code, description, type, typeParams, couponParams, } = this;
            for (let field in typeParams ) {
                const required = couponTypeRequiredFieldMap.get(type);
                if (!required.includes(field)) {
                    delete typeParams[field];
                }
            }
            try {
                await this.$store.dispatch(
                    "coupon/addCoupon",
                    {
                        code, description, type, ...typeParams, ...couponParams,
                        "shop": "5b980d38805573081e65f23e",
                    }
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                // TODO: show error toast
                return;
            }
            this.isLoading = false;
            this.$router.push("/shop/coupons");
        },
        async editCoupon () {
            this.isLoading = true;
            const { code, description, type, typeParams, couponParams, } = this;
            for (let field in typeParams ) {
                const required = couponTypeRequiredFieldMap.get(type);
                if (!required.includes(field)) {
                    typeParams[field] = null;
                }
            }
            if (new Date(couponParams.endDate).getTime() === 0) {
                couponParams.endDate = null;
            }
            try {
                await this.$store.dispatch(
                    "coupon/editCoupon",
                    {
                        code, description, type, ...typeParams, ...couponParams,
                        "_id": this.couponId,
                        "shop": "5b980d38805573081e65f23e",
                    }
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                // TODO: show error toast
                return;
            }
            this.isLoading = false;
            this.$router.push("/shop/coupons");
        },
    },
    watch: {
        coupon (newVal) {
            if (newVal) {
                const { code, description, type, ...rest } = newVal;
                this.code = code;
                this.description = description;
                this.type = type;
                const {
                    hasMinAmount, minAmount,
                    hasMinQuantity, minQuantity,
                    maxTotalTimes, isLimitedToOnePerUser,
                    startDate, endDate,
                    _id, shop, createdBy, updatedBy, timesUsed,
                    ...typeParams
                } = rest;
                this.couponParams = {
                    hasMinAmount, minAmount,
                    hasMinQuantity, minQuantity,
                    maxTotalTimes, isLimitedToOnePerUser,
                    "startDate": new Date(startDate),
                    "endDate": new Date(endDate),
                };
                this.typeParams = typeParams;
            }
        },
    },
    mounted () {
        if (this.coupon) {
            const { code, description, type, ...rest } = this.coupon;
            this.code = code;
            this.description = description;
            this.type = type;
            const {
                hasMinAmount, minAmount,
                hasMinQuantity, minQuantity,
                maxTotalTimes, isLimitedToOnePerUser,
                startDate, endDate,
                _id, shop, createdBy, updatedBy, timesUsed,
                ...typeParams
            } = rest;
            this.couponParams = {
                hasMinAmount, minAmount,
                hasMinQuantity, minQuantity,
                maxTotalTimes, isLimitedToOnePerUser,
                "startDate": new Date(startDate),
                "endDate": new Date(endDate),
            };
            this.typeParams = typeParams;
        }

    },
}
</script>

<style lang="scss" scoped>

</style>
