<template>
    <div class="card block">
        <div class="card-content pb-0">
            <h1 class="title is-5">Customer {{ customerBuysMode === "min-quantity" ? "buys" : "spends" }}</h1>

            <b-field>
                <b-radio native-value="min-quantity"
                         required
                         v-model="customerBuysMode">
                    Minimum quantity of items
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="min-amount"
                         required
                         v-model="customerBuysMode">
                    Minimum purchase amount
                </b-radio>
            </b-field>

            <b-field grouped
                     label="Quantity"
                     v-show="customerBuysMode === 'min-quantity'">
                <b-input v-cleave="masks.positiveInteger"
                         v-bind:value="buyQuantityFormatted"
                         v-on:input.native="onInput($event, 'buyQuantity')">
                </b-input>
            </b-field>
            <b-field grouped
                     label="Amount"
                     v-show="customerBuysMode === 'min-amount'">
                <b-input v-cleave="masks.monetary"
                         v-bind:value="buyAmountFormatted"
                         v-on:input.native="onInput($event, 'buyAmount')">
                </b-input>
            </b-field>

            <product-selector label="Targets"
                              v-model="customerBuys">
            </product-selector>
        </div>
        <hr>
        <div class="card-content pt-0 pb-0">
            <h1 class="title is-5 mb-3">Customer gets</h1>

            <p class="mb-5">
                To enjoy the discount, customers must add the quantity of items specified below to their cart.
            </p>

            <b-field grouped
                     label="Quantity">
                <b-input step="1"
                         min="0"
                         pattern="[0-9]"
                         v-cleave="masks.positiveInteger"
                         v-bind:value="getQuantityFormatted"
                         v-on:input.native="onInput($event, 'getQuantity')">
                </b-input>
            </b-field>

            <product-selector class="mb-4"
                              label="Targets"
                              v-model="customerGets">
            </product-selector>

            <h1 class="title is-6">At a discounted value</h1>

            <b-field>
                <b-radio native-value="percentage"
                         required
                         v-model="getDiscountMode">
                    Percentage
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="free"
                         required
                         v-model="getDiscountMode"
                         v-on:input="setFree">
                    Free (100% off)
                </b-radio>
            </b-field>

            <b-field grouped
                     v-show="getDiscountMode === 'percentage'">
                <b-input min="0"
                         v-cleave="masks.percentage"
                         v-bind:value="getDiscountFormatted"
                         v-on:input.native="onInput($event, 'getDiscount')">
                </b-input>
            </b-field>
        </div>
        <hr>
        <div class="card-content pt-0">
            <b-field>
                <b-checkbox v-model="isSetMaxUsePerOrder">
                    Set a maximum number of uses per order
                </b-checkbox>
            </b-field>
            <b-field grouped
                     v-if="isSetMaxUsePerOrder">
                <b-input min="1"
                         step="1"
                         pattern="[0-9]"
                         required
                         v-cleave="masks.positiveInteger"
                         v-bind:value="maxUsePerOrderFormatted"
                         v-on:input.native="onInput($event, 'maxUsePerOrder')">
                </b-input>
            </b-field>
        </div>
    </div>
</template>

<script>
import ProductSelector from "../ProductSelector";
import cleave, { percentage, monetary, positiveInteger } from "../../../../directives/VCleave";

export default {
    name: "CouponBuyXGetY",
    components: {
        ProductSelector
    },
    directives: {
        cleave,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {
            "masks": {
                percentage,
                monetary,
                positiveInteger
            },
            "customerBuysMode": "min-quantity",
            "buyQuantityFormattedInStore": "",
            "buyAmountFormattedInStore": "",
            "getQuantityFormattedInStore": "",
            "getDiscountMode": "percentage",
            "getDiscountFormattedInStore": "",
            "maxUsePerOrderFormattedInStore": "",
            "isSetMaxUsePerOrder": false,
        };
    },
    computed: {
        customerBuys: {
            get () {
                return this.value?.customerBuys ?? [];
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "customerBuys": val }
                );
            },
        },

        buyQuantity: {
            get () {
                return this.value?.buyQuantity ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "buyQuantity": val, "buyAmount": undefined }
                );
            }
        },
        buyQuantityFormatted: {
            get () {
                return this.buyQuantityFormattedInStore || this.buyQuantity;
            },
            set (val) {
                this.buyQuantityFormattedInStore = val;
            }
        },

        buyAmount: {
            get () {
                return this.value?.buyAmount ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "buyAmount": val, "buyQuantity": undefined }
                );
            }
        },
        buyAmountFormatted: {
            get () {
                return this.buyAmountFormattedInStore || this.buyAmount;
            },
            set (val) {
                this.buyAmountFormattedInStore = val;
            }
        },

        customerGets: {
            get () {
                return this.value?.customerGets ?? [];
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "customerGets": val }
                );
            },
        },

        getQuantity: {
            get () {
                return this.value?.getQuantity ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "getQuantity": val }
                );
            }
        },
        getQuantityFormatted: {
            get () {
                return this.getQuantityFormattedInStore || this.getQuantity;
            },
            set (val) {
                this.getQuantityFormattedInStore = val;
            }
        },

        getDiscount: {
            get () {
                return this.value?.getDiscount ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "getDiscount": val }
                );
            }
        },
        getDiscountFormatted: {
            get () {
                return this.getDiscountFormattedInStore || this.getDiscount;
            },
            set (val) {
                this.getDiscountFormattedInStore = val;
            }
        },

        maxUsePerOrder: {
            get () {
                return this.value?.maxUsePerOrder ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "maxUsePerOrder": val }
                );
            }
        },
        maxUsePerOrderFormatted: {
            get () {
                return this.maxUsePerOrderFormattedInStore || this.maxUsePerOrder;
            },
            set (val) {
                this.maxUsePerOrderFormattedInStore = val;
            }
        },
    },
    methods: {
        onInput (evt, target) {
            this.$nextTick(
                () => {
                    this[`${ target }Formatted`] = evt.target._vCleave.getFormattedValue();
                    this[target] = evt.target._vCleave.getRawValue().replace(/\D/g, "").trim();
                }
            );
        },
        setFree () {
            if (this.getDiscountMode === "free") {
                this.getDiscount = 100;
            }
        },
    },
    mounted () {
        if (this.getDiscount === 100) {
            this.getDiscountMode === "free";
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
