<template>
    <div class="card">
        <div class="card-content">
            <h1 class="title is-5">Types</h1>

            <b-field>
                <b-radio native-value="percentage"
                         v-model="type">
                    Percentage
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="fixed-amount"
                         v-model="type">
                    Fixed amount
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="free-shipping"
                         disabled
                         v-model="type">
                    Free shipping (WIP)
                </b-radio>
            </b-field>
            <b-field>
                <b-radio native-value="buy-x-get-y"
                         v-model="type">
                    Buy X get Y
                </b-radio>
            </b-field>
        </div>
    </div>
</template>

<script>
export default {
    name: "CouponType",
    props: {
        value: {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        type: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
