<template>
    <div class="card block">
        <div class="card-content">
            <h1 class="title is-5">Minimum requirements</h1>

            <b-field>
                <b-radio native-value="none"
                         v-model="type">
                    None
                </b-radio>
            </b-field>

            <b-field>
                <b-radio native-value="min-amount"
                         v-model="type">
                    Minimum order amount (HK$)
                </b-radio>
            </b-field>
            <b-field grouped
                     message="Applies to all products."
                     v-show="type === 'min-amount'">
                <b-input placeholder="HK$ 0.00"
                         v-cleave="masks.monetary"
                         v-bind:value="minAmountFormatted"
                         v-on:input.native="onInput($event, 'minAmount')">
                </b-input>
            </b-field>

            <b-field>
                <b-radio native-value="min-items"
                         v-model="type">
                    Minimum quantity of items
                </b-radio>
            </b-field>
            <b-field grouped
                     message="Applies to all products."
                     v-show="type === 'min-items'">
                <b-input v-cleave="masks.positiveInteger"
                         v-bind:value="minQuantityFormatted"
                         v-on:input.native="onInput($event, 'minQuantity')">
                </b-input>
            </b-field>
        </div>
    </div>
</template>

<script>
import cleave, { positiveInteger, monetary, } from "../../../directives/VCleave";

export default {
    name: "CouponMinimumRequirements",
    directives: {
        cleave,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {
            masks: {
                monetary,
                positiveInteger,
            },
            minAmountFormatted: "",
            minQuantityFormatted: "",
        };
    },
    computed: {
        type: {
            get () {
                return this.value?.hasMinAmount === true ?
                    "min-amount" : this.value?.hasMinQuantity ?
                        "min-items" :
                        "none";
            },
            set (val) {
                const temp = Object.assign({}, this.value);
                if (val === "min-amount") {
                    temp.hasMinAmount = true;
                    this.minQuantityFormatted = "";
                    delete temp.hasMinQuantity;
                    delete temp.minQuantity;
                } else if (val === "min-items") {
                    this.minAmountFormatted = "";
                    delete temp.hasMinAmount;
                    delete temp.minAmount;
                    temp.hasMinQuantity = true;
                } else {
                    delete temp.hasMinAmount;
                    delete temp.minAmount;
                    this.minAmountFormatted = "";
                    delete temp.hasMinQuantity;
                    delete temp.minQuantity;
                    this.minQuantityFormatted = "";
                }
                this.$emit("input", temp);
            }
        },

        minAmount: {
            get () {
                return this.value?.minAmount ?? "";
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "minAmount": val, }
                );
            }
        },

        minQuantity: {
            get () {
                return this.value?.minQuantity ?? "";
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "minQuantity": val, }
                );
            }
        },
    },
    methods: {
        onInput (evt, target) {
            this.$nextTick(
                () => {
                    this[`${ target }Formatted`] = evt.target._vCleave.getFormattedValue();
                    this[target] = evt.target._vCleave.getRawValue().replace(/\D/g, "").trim();
                }
            );
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
