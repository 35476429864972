import Cleave from "cleave.js";

export const percentage = {
    numeral: true,
    numeralPositiveOnly: true,
    prefix: " %",
    tailPrefix: true,
    numeralIntegerScale: 2,
    numeralDecimalScale: 0,
};

export const monetary = {
    numeral: true,
    numeralPositiveOnly: true,
    numeralThousandsGroupStyle: "thousand",
    prefix: "HK$ ",
    numeralDecimalScale: 2,
};

export const positiveInteger = {
    numeral: true,
    numeralPositiveOnly: true,
    numeralDecimalScale: 0,
};

const cleave = {
    name: "cleave",
    bind (el, binding) {
        const input = el.querySelector("input")
        input._vCleave = new Cleave(input, binding.value)
    },
    unbind (el) {
        const input = el.querySelector("input")
        input._vCleave.destroy()
    }
}

export default cleave;
