<template>
    <div class="card block">
        <div class="card-content">
            <h1 class="title is-5">Active dates</h1>

            <b-field grouped
                     label="Start date">
                <b-datetimepicker required
                                  placeholder="Click to select..."
                                  icon="calendar-today"
                                  position="is-top-right"
                                  horizontal-time-picker
                                  v-bind:timepicker="{ 'enableSeconds': false, 'hourFormat': '24' }"
                                  v-model="startDate">
                </b-datetimepicker>
            </b-field>

            <b-field>
                <b-checkbox v-model="isSetEndDate"
                            v-on:input="resetEndDate">
                    Set end date
                </b-checkbox>
            </b-field>

            <b-field v-if="isSetEndDate"
                     grouped
                     label="End date">
                <b-datetimepicker required
                                  placeholder="Click to select..."
                                  icon="calendar-today"
                                  position="is-top-right"
                                  horizontal-time-picker
                                  v-model="endDate">
                </b-datetimepicker>
            </b-field>
        </div>
    </div>
</template>

<script>
export default {
    name: "CouponActiveDates",
    props: {
        value: {
            type: Object,
        },
    },
    data () {
        return {
            isSetEndDateInStore: undefined,
        };
    },
    computed: {
        startDate: {
            get () {
                return this.value?.startDate ?? new Date();
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "startDate": val }
                );
            }
        },
        endDate: {
            get () {
                return this.value?.endDate ?? null;
            },
            set (val) {
                this.$emit(
                    "input",
                    { ...this.value, "endDate": val }
                );
            }
        },
        isSetEndDate: {
            get () {
                if (this.isSetEndDateInStore !== undefined) {
                    return this.isSetEndDateInStore;
                }
                return !!this.endDate;
            },
            set (val) {
                this.isSetEndDateInStore = val;
            }
        },
    },
    methods: {
        resetEndDate () {
            if (this.isSetEndDate === false) {
                this.endDate = null;
            }
        },
    },
    mounted () {
        if (!this.value?.startDate) {
            this.$emit(
                "input",
                { ...this.value, "startDate": new Date() }
            );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
