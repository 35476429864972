<template>
    <section>
        <b-field class="mb-5"
                 v-bind:label="label">
            <b-autocomplete placeholder="Search products"
                            icon="magnify"
                            clear-on-select
                            open-on-focus
                            group-field="series"
                            group-options="items"
                            field="title.en"
                            v-bind:data="filteredProducts"
                            v-model="searchTerm"
                            v-on:select="onSelect">
                <template v-slot:empty>No results found</template>
                <template slot-scope="props">
                    <div class="media">
                        <div class="media-left">
                            <p class="image is-64x64">
                                <img v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ props.option.images[0] }`">
                            </p>
                        </div>
                        <div class="media-content">
                            <p>
                                <strong>{{ props.option.title.en }}</strong>
                                <br>
                                {{ props.option.title["zh-hk"] }}
                            </p>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>

        <div>
            <article class="media"
                     v-for="{ _id, title, images } in value"
                     v-bind:key="_id">
                <figure class="media-left">
                    <p class="image is-64x64">
                        <img v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ images[0] }`">
                    </p>
                </figure>
                <div class="media-content">
                    <div class="content">
                        <p>
                            <strong>{{ title.en }}</strong>
                            <br>
                            {{ title["zh-hk"] }}
                        </p>
                    </div>
                </div>
                <div class="media-right">
                    <button class="delete"
                            v-on:click.prevent.stop="deleteFromTarget(_id)">
                    </button>
                </div>
            </article>
        </div>
    </section>
</template>

<script>
import groupBy from "lodash/groupBy";

export default {
    name: "ProductSelector",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        allowRepeat: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
    },
    data () {
        return {
            searchTerm: "",
            getTargets: [],
        };
    },
    computed: {
        series () {
            return this.$store.getters["shop/series"];
        },
        products () {
            return this.$store.getters["shop/products"];
        },
        productFuse () {
            return this.$store.getters["shop/productFuse"];
        },
        filteredProducts () {
            let products = this.searchTerm ?
                this.productFuse
                    .search(this.searchTerm)
                    .map(
                        ({ item }) => item
                    ) :
                this.products;
            if (this.allowRepeat === false) {
                const selected = this.value.map(({ _id }) => _id);
                products = products.filter(
                    ({ _id }) => !selected.includes(_id)
                );
            }
            const groups = groupBy(products, "series");
            return Object
                .keys(groups)
                .map(
                    (key) => {
                        return {
                            "series": this.series.find(({ _id }) => _id === key)?.title.en ?? key,
                            "items": groups[key]
                        };
                    }
                );
        },
    },
    methods: {
        onSelect (target) {
            if (target) {
                const temp = this.value.slice(0);
                temp.push(target);
                this.$emit("input", temp);
            }
            this.searchTerm = "";
        },
        deleteFromTarget (id) {
            const temp = this.value.slice(0);
            temp.splice(
                temp.findIndex(({ _id }) => _id === id),
                1
            );
            this.$emit("input", temp);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
